import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import jobDescriptions, { jobs } from "./Jobdescription";
import "./Home.css";

const gradientColors = [
  "#FDE8CD", // Light Orange
  "#E0F8E0", // Light Green
  "#F3E5F5", // Light Purple
  "#E0F7FA", // Light Blue
  "#FCE4EC", // Light Pink
  "#F5F5F5", // Light Grey
];

const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const shuffledColors = shuffleArray(gradientColors);

const getGradientBackground = (index) => {
  const color = shuffledColors[index % shuffledColors.length];
  return `linear-gradient(to bottom, ${color} 0%, ${color} calc(100% - 50px), transparent calc(100% - 50px))`;
};

export default function JobPosts() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationTerm, setLocationTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const JOBS_PER_PAGE = 14; // Display 14 jobs per page
  const navigate = useNavigate();

  const sortedJobs = [...jobs].sort((a, b) => {
    return new Date(b.postedDate) - new Date(a.postedDate);
  });

  const filteredJobs = sortedJobs.filter(
    (job) =>
      (job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (locationTerm === "" ||
        job.location.toLowerCase().includes(locationTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredJobs.length / JOBS_PER_PAGE);
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * JOBS_PER_PAGE,
    currentPage * JOBS_PER_PAGE
  );

  const handleCardClick = (job) => {
    if (window.innerWidth < 1024) {
      const jobTitleSlug = job.title.toLowerCase().replace(/\s+/g, "-");
      const locationSlug = job.location.toLowerCase().replace(/\s+/g, "-");
      navigate(`/hiring/${jobTitleSlug}/${locationSlug}`);
    } else {
      setSelectedJob(job);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8 mt-24 max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-[2fr,3fr] gap-8">
          {/* Job Cards Section */}
          <div>
            <div className="mb-4">
              <input
                type="text"
                className="block w-full p-2 border rounded-md mb-2"
                placeholder="Search by job title"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                className="block w-full p-2 border rounded-md"
                value={locationTerm}
                onChange={(e) => setLocationTerm(e.target.value)}
              >
                <option value="">All Locations</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="ON">Ontario</option>
                <option value="QC">Quebec</option>
              </select>
            </div>

            {/* Job Cards */}
            <div className="flex flex-col gap-4">
              {paginatedJobs.map((job, index) => (
                <div
                  key={job.id}
                  onClick={() => handleCardClick(job)}
                  className="relative border border-gray-300 rounded-2xl shadow-sm overflow-hidden cursor-pointer hover:border-gray-500"
                  style={{
                    background: getGradientBackground(index),
                    height: "350px", // Maintain card height
                  }}
                >
                  <div className="relative p-6 flex flex-col h-full">
                    <div className="absolute top-4 left-4 bg-white text-gray-500 rounded-lg px-3 py-1 shadow-sm">
                      <p>{job.postedDate}</p>
                    </div>
                    <div className="mt-16 text-2xl font-semibold text-gray-800">
                      {job.title}
                    </div>
                    <div className="mt-4 mb-4 flex flex-wrap gap-2">
                      {job.keyword1 && (
                        <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
                          {job.keyword1}
                        </span>
                      )}
                      {job.keyword2 && (
                        <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
                          {job.keyword2}
                        </span>
                      )}
                    </div>
                    <div
                      className="absolute bottom-3 left-0 right-0 px-4"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "16px",
                        height: "70px",
                        paddingTop: "15px",
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-black">{job.salary}</p>
                          <p className="text-black">{job.location}</p>
                        </div>
                        <button className="button-24">Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-8">
              <nav className="inline-flex items-center border rounded-md">
                <button
                  className={`px-3 py-2 rounded-l-md ${
                    currentPage === 1
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                  }`}
                  onClick={() =>
                    currentPage > 1 && handlePageChange(currentPage - 1)
                  }
                  disabled={currentPage === 1}
                >
                  «
                </button>
                {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
                  const page =
                    index + Math.max(1, Math.min(currentPage - 2, totalPages - 4));
                  return (
                    <button
                      key={page}
                      className={`px-3 py-2 ${
                        currentPage === page
                          ? "bg-gray-800 text-white font-bold"
                          : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  );
                })}
                <button
                  className={`px-3 py-2 rounded-r-md ${
                    currentPage === totalPages
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                  }`}
                  onClick={() =>
                    currentPage < totalPages && handlePageChange(currentPage + 1)
                  }
                  disabled={currentPage === totalPages}
                >
                  »
                </button>
              </nav>
            </div>
          </div>

          {/* Job Details Section */}
          <div className="bg-white shadow-md rounded-lg p-0 border border-gray-300 sticky top-24 h-[calc(100vh-8rem)]">
            {selectedJob ? (
              <>
                {/* Sticky Header */}
                <div className="bg-gray-100 p-4 border-b border-gray-300 sticky top-0 z-10">
                  <h2 className="text-2xl font-semibold text-gray-800">
                    {selectedJob.title}
                  </h2>
                  <p className="text-gray-500">{selectedJob.location}</p>
                </div>
                {/* Scrollable Content */}
                <div className="p-6 overflow-y-auto h-[calc(100vh-16rem)]">
                  <p className="text-gray-700 mt-2">{selectedJob.salary}</p>
                  <div
                    className="mt-4 text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: jobDescriptions[selectedJob.descriptionKey],
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="text-center text-gray-500 p-6">
                Select a job to view details
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
