import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import jobDescriptions, { jobs } from "./Jobdescription";

export default function JobDetails() {
  const { jobTitle, location } = useParams();
  const job = jobs.find(
    (job) =>
      job.title.toLowerCase().replace(/\s+/g, "-") === jobTitle &&
      job.location.toLowerCase().replace(/\s+/g, "-") === location
  );

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8 mt-16">
        <div className="p-4 border rounded-md">
          <h2 className="text-2xl font-semibold">{job.title}</h2>
          <p className="text-gray-600">Location: {job.location}</p>
          <p className="text-gray-600">Salary: {job.salary}</p>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: jobDescriptions[job.descriptionKey],
            }}
          />
        </div>
      </div>
    </>
  );
}
